<template>
  <v-container
    fluid
  >
    <basic-card
      title="$l.paths.feedback"
      class="mt-9"
    >
      <app-api-call
        :call-result="callResult"
      />

      <v-row
        ref="feedbackThankYou"
      >
        <v-card-text
          class="text-h6 d-flex justify-center text-center ma-0 pa-1"
        >
          {{ '$l.feedback.request' | translate }}
        </v-card-text>
      </v-row>

      <v-row>
        <v-card-text class="d-flex justify-center text-center  ma-0 pa-1">
          <v-divider class="mx-4 secondary" />
        </v-card-text>
      </v-row>

      <v-row>
        <v-card-text class="text-h6 d-flex justify-center text-center ma-0 pa-1">
          {{ '$l.feedback.emoticon' | translate }}
        </v-card-text>
      </v-row>

      <v-row
        justify="center"
        @click="feedbackHasBeenSent = false"
      >
        <v-btn-toggle
          v-model="toggle_experience"
          class="ma-0 pa-0 my-3"
          color="primary"
          group
          tile
        >
          <v-btn
            v-for="(emoticon, index) in emoticons"
            :key="index"
            fab
            icon
            class="ma-0 pa-0 mx-0"
            :small="$vuetify.breakpoint.xs"
          >
            <v-icon
              x-large
            >
              {{ emoticon }}
            </v-icon>
          </v-btn>
        </v-btn-toggle>
      </v-row>

      <v-row
        v-if="$v.toggle_experience.$dirty && !$v.toggle_experience.required"
        class="ma-0 pa-0"
      >
        <v-card-text class="subtitle-1 d-flex justify-center text-center ma-0 pa-0 error--text">
          {{ '$l.app.required' | translate }}
        </v-card-text>
      </v-row>

      <v-row>
        <v-card-text class="d-flex justify-center text-center ma-0 pa-1">
          <v-divider class="mx-4 secondary" />
        </v-card-text>
      </v-row>

      <v-row>
        <v-card-text class="text-h6 d-flex justify-center text-center ma-0 pa-1">
          {{ '$l.feedback.category' | translate }}
        </v-card-text>
      </v-row>

      <v-row
        justify="center"
        class="pa-0 px-5"
      >
        <v-radio-group
          v-model="toggle_category"
          row
          class="ma-0 pa-1"
        >
          <v-radio
            class="my-1"
            :label="$i18n.t('$l.feedback.kompliment')"
            value="1"
          />
          <v-radio
            class="my-1"
            :label="$i18n.t('$l.feedback.proposal')"
            value="2"
          />
          <v-radio
            class="my-1"
            :label="$i18n.t('$l.feedback.reklamacia')"
            value="3"
          />
        </v-radio-group>
      </v-row>

      <v-row
        v-if="$v.toggle_category.$dirty && !$v.toggle_category.required"
        class="ma-0 pa-0"
      >
        <v-card-text class="subtitle-1 d-flex justify-center text-center ma-0 pa-0 error--text">
          {{ '$l.app.required' | translate }}
        </v-card-text>
      </v-row>

      <v-row>
        <v-card-text class="d-flex justify-center text-center ma-0 pa-1">
          <v-divider class="mx-4 secondary" />
        </v-card-text>
      </v-row>

      <v-row>
        <v-card-text class="text-h6 d-flex justify-center text-center ma-0 pa-1 mb-2">
          {{ '$l.feedback.experience' | translate }}
        </v-card-text>
      </v-row>

      <v-row
        justify="center"
        class="ma-0"
      >
        <v-textarea
          ref="textarea"
          v-model="experience_text"
          class="ma-0"
          outlined
          filled
          background-color="greyee"
          color="success darken-1"
          :placeholder="'$l.app.min10charsRequired' | translate"
          prepend-inner-icon="mdi-comment-text"
          :rules="[v => v ? (v.length >= 10 ? true : $i18n.t('$l.app.min10charsRequired')) : '']"
          maxlength="512"
          counter
        />
      </v-row>

      <v-row
        v-if="$v.experience_text.$dirty && !$v.experience_text.required"
        class="ma-0 pa-0 mb-2"
        style="top: 300px"
      >
        <v-card-text class="subtitle-1 d-flex justify-center text-center ma-0 pa-0 error--text">
          {{ '$l.app.required' | translate }}
        </v-card-text>
      </v-row>

      <v-row>
        <v-card-text class="d-flex justify-center text-center ma-0 pa-1">
          <v-divider class="mx-4 secondary mb-2" />
        </v-card-text>
      </v-row>

      <v-row justify="center">
        <v-btn
          class="ma-0 primary"
          x-large
          text
          :loading="isSendingFeedback"
          @click="sendFeedback()"
        >
          {{ '$l.app.send' | translate }}
        </v-btn>
      </v-row>
    </basic-card>

    <basic-dialog
      v-model="feedbackHasBeenSent"
      title="$l.feedback.sent"
      @close-dialog="feedbackHasBeenSent = false"
    >
      <v-card-title class="success--text">
        {{ '$l.feedback.thankYou' | translate }}
      </v-card-title>
    </basic-dialog>
  </v-container>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'OkamlFeedback',
  data () {
    return {
      callResult: { finished: true, authorized: false, error: null, info: null },
      emoticons: [
        'mdi-emoticon-outline',
        'mdi-emoticon-happy-outline',
        'mdi-emoticon-neutral-outline',
        'mdi-emoticon-sad-outline',
        'mdi-emoticon-frown-outline'
      ],
      toggle_experience: undefined,
      toggle_category: undefined,
      experience_text: '',
      isSendingFeedback: false,
      feedbackHasBeenSent: false
    }
  },
  validations: {
    toggle_experience: { required },
    toggle_category: { required },
    experience_text: { required }
  },
  methods: {
    sendFeedback () {
      this.callResult.error = null
      this.$v.$touch()
      if (!this.$v.$invalid && this.$refs.textarea.validate()) {
        this.isSendingFeedback = true
        this.callResult.finished = false
        this.$xapi.put('endpointsFeedback', { experience: this.toggle_experience, category: this.toggle_category, text: this.experience_text })
          .then(r => {
            this.feedbackHasBeenSent = true
          })
          .catch(e => {
            this.callResult.error = e.response.localizedMessage
          })
          .finally(() => {
            this.toggle_experience = undefined
            this.toggle_category = undefined
            this.experience_text = ''
            this.$v.toggle_experience.$reset()
            this.$v.toggle_category.$reset()
            this.$v.experience_text.$reset()
            this.$refs.textarea.reset()
            this.isSendingFeedback = false
            this.callResult.finished = true
          })
      }
    }
  }
}
</script>
