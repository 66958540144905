<template>
  <OkamlFeedback />
</template>

<script>
import OkamlFeedback from '@/components/feedback/OkamlFeedback'

export default {
  name: 'Feedback',
  components: {
    OkamlFeedback
  }
}
</script>
